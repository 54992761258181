import { login } from "@/api/auth";

import { getToken, setToken } from "@/utils/auth";

const user = {
  namespaced: true,
  state: {
    token: getToken(),
    name: "",
    avatar: "",
    roles: [],
    permissions: [],
    menus: [],
    info: {},
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_INFO: (state, payload) => {
      state.info = payload;
    },
  },
  actions: {
    onLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login({
          ...userInfo,
        })
          .then((res) => {
            console.log(res);
            commit("SET_TOKEN", res.token);
            setToken(res.token);
            sessionStorage.setItem("userId", res.user.id);
            // eslint-disable-next-line no-unused-vars
            const { user, ...userInfo } = res.account;
            commit("SET_INFO", userInfo);
            commit("SET_NAME", userInfo.username);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default user;
