import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import PageTitle from "@/components/page/PageTitle.vue";
import PagePanel from "@/components/page/PagePanel.vue";
import YButton from "@/components/common/YButton";
import YTable from "@/components/common/YTable";
import YUpload from "@/components/common/YUpload";
import YRichtext from "@/components/common/YRichtext";
import YLoading from "@/components/common/YLoading";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";

import "@/assets/css/index.less";

// import "@/utils/area";

const app = createApp(App);

app.component("PageTitle", PageTitle);
app.component("PagePanel", PagePanel);
app.component("YButton", YButton);
app.component("YTable", YTable);
app.component("YUpload", YUpload);
app.component("YLoading", YLoading);
app.component("YRichtext", YRichtext);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(Antd).use(pinia).use(store).use(router).mount("#app");
