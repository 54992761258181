import axios from "axios";
import { message } from "ant-design-vue";

const MESSAGE_KEY = "messageKey";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 1000 * 180,
});

instance.interceptors.request.use((config) => {
  config.headers["X-API-KEY"] = 123456;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  (error) => {
    if (error && error.response) {
      message.error({
        content: error.response?.data?.message || "接口错误",
        // content: "接口错误",
        duration: 2,
        key: MESSAGE_KEY,
      });
    } else {
      if (error.code == "ERR_NETWORK") {
        message.error({
          key: MESSAGE_KEY,
          content: "ERR_NETWORK",
        });
      } else {
        message.error({
          key: MESSAGE_KEY,
          content: "接口连接异常",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
