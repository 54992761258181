<template>
  <header class="page-header">
    <div class="logo">
      <img src="../../assets/images/top/logo.png" />
      <span class="title">新万基视频监控中台</span>
    </div>
    <div class="header-right">
      <div class="new-alarm">
        <span class="time">{{ newAlarm.time }}</span>
        <span class="camera">{{ newAlarm.camera }}</span>
        <span class="alarm">{{ newAlarm.alarm }}</span>
        <div @click="showModal()">
          <img src="../../assets/images/top/alarm.png" />
          <div>{{ newAlarm.total > 99 ? "99+" : newAlarm.total }}</div>
        </div>
      </div>
      <div class="date">{{ dateLabel }}</div>
      <div class="user">
        <img src="../../assets/images/top/icon-user.png" class="icon" />
        <span>{{
          accountInfo && accountInfo.accountInfo && accountInfo.accountInfo.name
        }}</span>
      </div>
      <div class="logout" @click="logout">
        <img src="../../assets/images/top/icon-logout.png" class="icon" />
        <span>Logout</span>
      </div>
    </div>
  </header>
  <alarm-list
    v-if="visible"
    v-model:visible="visible"
    @success="handleSuccess"
  ></alarm-list>
</template>

<script>
import dayjs from "dayjs";
// import { mapState } from "pinia";
import { Modal } from "ant-design-vue";
import { onMounted, reactive, ref } from "vue";
import { renderAlarmType } from "@/utils/ys-alarm-types";
import { useRouter } from "vue-router";
import { setToken } from "@/utils/auth";
import AlarmList from "./SideBar/AlarmList.vue";
import { useUserStore } from "@/stores/user";
import { getList } from "@/api/videoAlarm";

export default {
  components: {
    AlarmList,
  },
  setup() {
    const router = useRouter();
    const dateLabel = dayjs().format(`dddd, MMM D, YYYY`);
    const newAlarm = reactive({
      time: "",
      camera: "",
      alarm: "",
      total: "",
    });
    const accountInfo = useUserStore();
    onMounted(() => {
      getVideoAlarms();
    });
    const handleSuccess = () => {
      getVideoAlarms();
      // console.log("最外层");
    };

    const getData = async () => {
      const res = await getList({
        sort: "alarmTime,DESC",
        // ...params,
        isRead: false,
        page: 0,
      });
      console.log(res);
      if (res?.content[0]) {
        const data = res.content[0];
        if (data.alarmTime) {
          newAlarm.time = dayjs(data.alarmTime).format("YYYY-MM-DD HH:mm:ss");
        }
        newAlarm.camera = data.camera.name;
        newAlarm.alarm = renderAlarmType(data.alarmType);
      }
      newAlarm.total = res.totalElements;
      newAlarm.data = console.log(newAlarm.time);
    };
    const getVideoAlarms = async () => {
      getData();
      setInterval(getData, 20000);
    };
    const visible = ref(false);
    const showModal = () => {
      visible.value = true;
    };

    const logout = () => {
      const store = useUserStore();
      Modal.confirm({
        title: "退出",
        content: "确定退出系统吗？",
        onOk() {
          store.$reset();
          setToken("");
          router.push("/login");
          // window.location.href = '/login'
        },
        onCancel() {},
      });
    };
    // const comput = computed({
    //   ...mapState(useUserStore, ["accountInfo"]),
    // });
    return {
      accountInfo,
      dateLabel,
      userName: "",
      newAlarm,
      visible,
      getVideoAlarms,
      showModal,
      logout,
      handleSuccess,
      // comput,
    };
  },
  // computed: {
  //   ...mapState(useUserStore, ["accountInfo"]),
  // },
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  background: #192a87;
  height: 60px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  .logo {
    display: flex;
    align-items: center;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 60px;
      margin-left: 15px;
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    div {
      margin-left: 12px;
      display: flex;
    }
    .new-alarm {
      // margin-right: 12px;
      > span {
        margin-right: 6px;
      }
      > div {
        margin-left: 4px;
        width: 60px;
        height: 24px;
        background: #ff3e3e;
        border-radius: 40px;
        cursor: pointer;
        align-items: center;
        > img {
          height: 14px;
          height: 14px;
          margin: 0 8px;
          // margin: 2px 10px;
        }
        > div {
          margin-left: 0;
        }
      }
      .time {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }
      .camera {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .alarm {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff3e3e;
      }
    }
    .date {
      padding: 0 13px;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
    }
    .user,
    .logout {
      cursor: pointer;
    }
    div span {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}
</style>

<style>
@keyframes imp {
  0% {
    color: red;
  }
  25% {
    color: rgb(0, 255, 38);
  }
  50% {
    color: yellow;
  }
  75% {
    color: rgb(201, 4, 109);
  }
  100% {
    color: khaki;
  }
}
</style>
