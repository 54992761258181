import { defineStore } from "pinia";

import { getAccessToken } from "@/api/video";

export const useYingshiStore = defineStore("ys", {
  persist: {
    storage: sessionStorage,
  },
  state: () => ({
    accessToken: "",
    expireTime: null,
  }),
  actions: {
    async getToken() {
      const now = Date.now();
      if (now > this.expireTime) {
        const res = await getAccessToken();
        console.log(res);
        this.accessToken = res.accessToken;
        this.expireTime = res.expireTime;
      }
    },
  },
});
