<template>
  <component
    :is="menuComponent"
    :item="item"
    :key="item.id"
    :route-children="routeChildren"
  >
    <template v-if="item.children && item.children.length">
      <layout-menu
        v-for="route in item.children"
        :key="route.id"
        :item="route"
      ></layout-menu>
    </template>
  </component>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import SubMenu from "./SubMenu.vue";

export default {
  components: {
    MenuItem,
    SubMenu,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routeChildren: {},
      menuComponent: "",
    };
  },
  created() {
    const showChildren = this.handleChildren(this.item.children);
    if (showChildren.length === 0) {
      this.menuComponent = "MenuItem";
      this.routeChildren = this.item;
    } else if (showChildren.length === 1 && this.item.alwaysShow !== true) {
      // this.menuComponent = "MenuItem";
      // this.routeChildren = showChildren[0];
      this.menuComponent = "SubMenu";
    } else {
      this.menuComponent = "SubMenu";
    }
  },
  methods: {
    handleChildren(children = []) {
      if (children === null) return [];
      return children.filter((item) => item.hidden !== true);
    },
  },
};
</script>
