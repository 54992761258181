<template>
  <a-modal
    :visible="visible"
    title="实时告警"
    cancelText="取消"
    okText="保存"
    :footer="false"
    @ok="handleOk"
    @cancel="handleClose"
    width="1320px"
  >
    <div class="table-box">
      <y-table
        :loading="table.loading"
        :columns="table.columns"
        :data-source="table.dataSource"
        :total="table.total"
        v-model:curr-page="searchForm.page"
        v-model:page-size="searchForm.size"
        @change="getVideoAlarms"
        :customRow="customRow"
      >
      </y-table>
    </div>
    <record-modal
      :record="selectedItem"
      v-model:visible="visibleModal"
      v-if="visibleModal"
      @success="handleSuccess"
    ></record-modal>
  </a-modal>
</template>

<script setup lang="jsx">
import { defineEmits, onMounted, reactive, ref } from "vue";
import { getList } from "@/api/videoAlarm";
import dayjs from "dayjs";
import { renderAlarmType } from "@/utils/ys-alarm-types";
import { renderArea } from "@/utils/utils";
import RecordModal from "./components/add-modal.vue";

const searchForm = reactive({
  page: 0,
  size: 10,
  isRead: false,
  sort: "alarmTime,DESC",
});
const table = reactive({
  // total: 0,
  dataSource: [],
  columns: [
    {
      title: "序号",
      customRender: ({ index }) => ++index,
    },
    {
      dataIndex: "alarmTime",
      title: "告警时间",
      align: "center",
      customRender: ({ text }) => dayjs(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      dataIndex: ["camera", "name"],
      title: "摄像头",
      align: "center",
    },
    {
      dataIndex: "alarmType",
      title: "告警类型",
      align: "center",
      customRender: ({ text }) => {
        return <span style="color:red">{renderAlarmType(text)}</span>;
      },
    },
    {
      title: "设备编号",
      dataIndex: ["camera", "serialNumber"],
      align: "center",
    },
    {
      title: "卫星小站ID",
      dataIndex: ["camera", "satelliteEarthStationId"],
      align: "center",
      customRender: ({ text }) => {
        return text ? text : "/";
      },
    },
    {
      title: "归属地区",
      dataIndex: ["camera", "areaCode"],
      customRender: ({ text }) => renderArea(text, true),
      align: "center",
    },
  ],
});

const visibleModal = ref(false);
const selectedItem = ref();
const customRow = (record) => {
  return {
    onClick: () => {
      selectedItem.value = record;
      visibleModal.value = true;
      console.log(record);
    },
  };
};

const handleSuccess = (val) => {
  console.log(val);
  searchForm.page = 0;
  getVideoAlarms();
  // console.log("中间层");
  if (val) {
    handleClose();
  }
};
const getVideoAlarms = async () => {
  table.loading = true;
  const res = await getList({
    ...searchForm,
  });
  table.loading = false;
  table.total = res.totalElements;
  table.dataSource = res.content;
};
onMounted(() => {
  getVideoAlarms();
});
const emit = defineEmits(["ok", "cancel"]);
const handleClose = () => {
  emit("update:visible", false);
  emit("success");
};
</script>
<style lang="less" scoped>
.table-box {
  ::v-deep {
    .ant-table-tbody {
      cursor: pointer;
    }
  }
}
// .table-box {
//   height: 400px;
//   overflow: hidden;
//   overflow: auto;
// }
</style>
