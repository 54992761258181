const alarmTypes = {
  pir: "人体感应事件",
  callhelp: "紧急遥控按钮事件",
  motiondetect: "移动侦测告警",
  babycry: "婴儿啼哭",
  magnetometer: "门磁告警",
  fire: "烟感告警",
  gas: "可燃气体告警",
  waterlogging: "水浸告警",
  emergency: "紧急按钮告警",
  infrared: "人体感应告警",
  shelteralarm: "遮挡告警",
  videoloss: "视频丢失",
  linedetection: "越界侦测",
  fielddetection: "区域入侵",
  facedetection: "人脸检测事件",
  doorbell: "智能门铃告警",
  curtain: "幕帘告警",
  move_magnetometer: "单体门磁告警",
  scenechangedetection: "场景变更侦测",
  defocus: "虚焦侦测",
  audioexception: "音频异常侦测",
  leftdetection: "物品遗留侦测",
  takedetection: "物品拿取侦测",
  parkingdetection: "非法停车侦测",
  highdensitydetection: "人员聚集侦测",
  loiterdetection: "徘徊检测侦测",
  rundetection: "快速移动侦测",
  enterareadetection: "进入区域侦测",
  exitareadetection: "离开区域侦测",
  mag_gim: "磁干扰告警",
  UnderVoltage: "电池电量低告警",
  intrusion: "闯入告警",
  baby_motion: "婴儿大动作告警",
  PowerChange: "电源切换告警",
  hightemperature: "温度过高告警",
  lowtemperature: "温度过低告警",
  highhumidity: "湿度过高告警",
  lowhumidity: "湿度过低告警",
  thermalimagingfire: "热成像火点告警",
  whitelistface: "陌生人告警",
  "DoorLock-FingerprintOpenDoor": "指纹开门",
  "DoorLock-PasswordOpenDoor": "密码开门",
  "DoorLock-SwipeOpenDoor": "刷卡开门",
  "DoorLock-CenterRemoteOpenDoor": "中心远程开门",
  "DoorLock-AppRemoteOpenDoor": "APP远程开门",
  "DoorLock-KeyOpenDoor": "钥匙开门",
  "DoorLock-FingerprintInfoChange": "指纹信息改动",
  "DoorLock-RemoteDeviceOpenDoor": "遥控器开门",
  "DoorLock-CardInfoChange": "卡信息改动",
  "DoorLock-PasswordInfoChange": "密码信息改动",
  "DoorLock-UserInfoChange": "用户信息改动",
  "DoorLock-SystemInfoChange": "系统信息改动",
  "DoorLock-AntiHijackingFingerprint": "防劫持指纹告警",
  "DoorLock-AntiHijackingPassword": "防劫持密码告警",
  "DoorLock-PryDoor": "撬门告警",
  "DoorLock-Locked": "门锁锁定告警",
  "DoorLock-BatteryLow": "电量不足告警",
  "DoorLock-Blacklist": "黑名单告警",
  "DoorLock-Offline": "掉线告警",
  callmsg: "呼叫消息",
  hangupmsg: "挂断消息",
  "DoorLock-DoorOpenAlarm": "智能锁开门提醒",
  "DoorLock-ConfigChangeAlarm": "智能锁配置改动提醒",
  "DoorLock-DoorbellAlarm": "门铃提醒",
  "DoorLock-Reset": "智能锁复位",
  "DoorLock-DataClear": "智能锁数据清空",
  opendoormsg: "开门消息",
  nothomemsg: "未回家消息",
  lowbattery: "电量不足告警",
  abnormalopendoor: "异常开门告警",
  unclosed: "门未关告警",
  hijack: "挟持告警",
  pry: "开门消息",
  io: "IO告警",
  "io-1": "IO-1告警",
  "io-2": "IO-2告警",
  "io-3": "IO-3告警",
  "io-4": "IO-4告警",
  "io-5": "IO-5告警",
  "io-6": "IO-6告警",
  "io-7": "IO-7告警",
  "io-8": "IO-8告警",
  "io-9": "IO-9告警",
  "io-10": "IO-10告警",
  "io-11": "IO-11告警",
  "io-12": "IO-12告警",
  "io-13": "IO-13告警",
  "io-14": "IO-14告警",
  "io-15": "IO-15告警",
  "io-16": "IO-16告警",
  VMD: "移动侦测告警开始",
  tamperdetection: "遮挡告警开始",
  alarmTrig: "即时防区告警",
  alarmRest: "即时防区恢复",
  audio24Trig: "24小时有声防区告警",
  audio24Rest: "24小时有声防区恢复",
  enterexitTrig: "延时防区告警",
  enterexitRest: "延时防区恢复",
  internalTrig: "内部延时防区告警",
  internalRest: "内部延时防区恢复",
  fireTrig: "火警防区告警",
  fireRest: "火警防区恢复",
  perimeterTrig: "周界防区告警",
  perimeterRest: "周界防区恢复",
  silent24Trig: "24小时无声防区告警",
  silent24Rest: "24小时无声防区恢复",
  aux24Trig: "24小时辅助防区告警",
  aux24Rest: "24小时辅助防区恢复",
  vib24Trig: "24小时震动防区告警",
  vib24Rest: "24小时震动防区恢复",
  sensorDemol: "防区感应器被拆",
  sensorRecov: "防区感应器被拆恢复",
  softEmergTrig: "软防区紧急告警",
  softFireTrig: "软防区火警",
  softBanditTrig: "软防区匪警",
  hostageReport: "挟持报告",
  devTampering: "设备防拆",
  devTamperRecov: "设备防拆恢复",
  ACDown: "交流电掉电",
  ACRecov: "交流电恢复",
  lowBatVol: "蓄电池电压低",
  norBatVol: "蓄电池电压正常",
  telDiscon: "电话线断开",
  telRecov: "电话线连接",
  XBUSDiscon: "扩展总线模块掉线",
  XBUSRecov: "扩展总线模块掉线恢复",
  keyboardDisconn: "键盘掉线",
  keyboardRecov: "键盘恢复",
  KBUSTrigDiscon: "键盘总线上触发器掉线",
  KBUSTrigRecov: "键盘总线上触发器恢复",
  autoArmFail: "自动布防失败",
  autoDisarmFail: "自动撤防失败",
  wirelessExcep: "无线网络异常",
  wirelessRecov: "无线网络恢复正常",
  SIMExcep: "SIM卡异常",
  SIMRecov: "SIM卡恢复正常",
  hostReset: "主机复位",
  disarm: "撤防",
  arm: "布防",
  autoDisarm: "自动撤防",
  autoArm: "自动布防",
  clear: "消警",
  instantArm: "即时布防",
  keyDisarm: "钥匙防区撤防",
  keyArm: "钥匙防区布防",
  stayArm: "留守布防",
  forcedArm: "强制布防",
  bypass: "旁路",
  bypassRecov: "旁路恢复",
  grpBypass: "子系统组旁路",
  grpBypassRecov: "子系统组旁路恢复",
  manualRep: "手动测试报告",
  timingRep: "定时测试报告",
  singleDisarm: "单防区撤防",
  singleArm: "单防区布防",
  keypadLock: "键盘锁定",
  keypadUnlock: "键盘解锁",
  printerOff: "打印机掉线",
  printerOn: "打印机恢复",
  instantDisarm: "即时撤防",
  stayDisarm: "留守撤防",
  triggerScheOn: "定时开启触发器",
  triggerScheOff: "定时关闭触发器",
  acTrigFail: "定时开启触发器失败",
  deacTrigFail: "定时关闭触发器失败",
  enterProgram: "进入编程",
  exitProgram: "退出编程",
  GPKDiscon: "键盘总线上GP/K掉线",
  GPKRecov: "键盘总线上GP/K恢复",
  MNKDiscon: "键盘总线上MN/K掉线",
  MNKRecov: "键盘总线上MN/K恢复",
  IPConflict: "IP冲突",
  IPRecov: "IP正常",
  netDiscon: "网线断开",
  netRecov: "网线正常",
  VMDRecov: "移动侦测告警结束",
  tamperRecov: "遮挡告警结束",
  videoRecov: "视频信号恢复",
  viIOUnmatch: "输入/输出视频制式不匹配",
  viIORecov: "输入/输出视频制式恢复正常",
  viInputExc: "视频输入异常",
  viInputRecov: "视频输入恢复正常",
  HDDfull: "硬盘满",
  HDDfree: "硬盘空闲",
  HDDexception: "硬盘出错",
  HDDRecov: "硬盘恢复正常",
  uploadPicFail: "图片上传失败",
  DetOffline: "探测器离线",
  DetOnline: "探测器恢复在线",
  DetLowBattery: "探测器电量欠压",
  DetBatteryRecov: "探测器电量恢复正常",
  zoneAddDet: "防区添加探测器",
  zoneDeleteDet: "防区删除探测器",
  wifiException: "WIFI通信异常",
  wifiRecov: "WIFI通信恢复正常",
  rfException: "RF信号异常",
  rfRecov: "RF信号恢复正常",
  MINOR_HOST_DESMANTLE_ALARM: "主机防拆告警",
  MINOR_HOST_DESMANTLE_RESUME: "主机防拆恢复",
  MINOR_CARD_READER_DESMANTLE_ALARM: "读卡器防拆告警",
  MINOR_CARD_READER_DESMANTLE_RESUME: "读卡器防拆恢复",
  MINOR_CASE_SENSOR_ALARM: "事件输入告警",
  MINOR_CASE_SENSOR_RESUME: "事件输入恢复",
  MINOR_SECURITY_MODULE_DESMANTLE_ALARM: "门控安全模块防拆告警",
  MINOR_SECURITY_MODULE_DESMANTLE_RESUME: "门控安全模块防拆恢复",
  MINOR_NET_BROKEN: "网络断开",
  MINOR_NET_RESUME: "网络恢复",
  MINOR_DEV_POWER_ON: "设备上电启动",
  MINOR_DEV_POWER_OFF: "设备掉电关闭",
  MINOR_DOOR_OPEN_ABNORMAL: "门异常打开（门磁）",
  emergHelp: "紧急呼叫求助告警",
  emergHelpRecov: "紧急呼叫求助恢复",
  consulting: "业务咨询开始",
  consulted: "业务咨询结束",
  overAlarm: "超时告警",
  overAlarmRecov: "超时告警恢复",
  singleCancelArm: "单防区消警",
  BUSQuery: "总线搜索",
  BUSOpen: "总线开路告警",
  BUSOpenRecov: "总线开路告警恢复",
  BUSShort: "总线短路告警",
  BUSShortRecov: "总线短路告警恢复",
  exModuleExcep: "扩展模块异常",
  exModuleRecov: "扩展模块恢复",
  exModuleLowVol: "扩展模块电压低",
  exModuleVolRecov: "扩展模块电压正常",
  exModuleTamp: "扩展模块防拆触发",
  exModuleTampRecov: "扩展模块防拆恢复",
  exModuleAcDown: "扩展模块交流电掉电",
  exModuleAcOn: "扩展模块交流电恢复",
  repeaterTamp: "无线中继器防拆触发",
  repeaterTampRecov: "无线中继器防拆恢复",
  zoneOpenAlarm: "防区开路提醒",
  zoneShortAlarm: "防区短路提醒",
  telConTest: "电话链路测试",
  exModuleDiscon: "扩展模块掉线",
  exModuleCon: "扩展模块掉线恢复",
  wlModuleDiscon: "无线扩展模块掉线",
  wlModuleCon: "无线扩展模块掉线恢复",
  repeaterDiscon: "无线中继器掉线",
  repeaterCon: "无线中继器掉线恢复",
  netExceed: "流量超额",
  delExModule: "删除扩展模块",
  addExModule: "添加扩展模块",
  delRepeater: "删除无线中继器",
  addRepeater: "添加无线中继器",
  BUSRegistration: "总线注册",
  "DoorLock-UnClosed": "门锁虚掩告警",
  audiomsg: "语音留言消息",
  videomsg: "视频留言消息",
  offline: "设备下线",
  ThirdCaption: "第三方抓图",
  Linkage: "互联互通",
};

const renderAlarmType = (code) => {
  const alarm = alarmTypes[code];
  return alarm || "/";
};

const alarmTypeValues = Object.entries(alarmTypes).map(([k, v]) => ({
  key: k,
  name: v,
}));

export { alarmTypeValues, renderAlarmType };

export default alarmTypes;
