import request from "./index";

export function getAccessToken() {
  return request({
    url: "/yingshi/access-token",
    method: "get",
  });
}

export function getVideoUrl(params) {
  return request({
    url: "/live/address",
    method: "get",
    params,
  });
}

export function getDmiUrl(params) {
  return request({
    url: "/dmi/live",
    method: "get",
    params,
  });
}

export function dmiPtz(data) {
  return request({
    url: "/dmi/ptz",
    method: "post",
    data,
  });
}

export function ptzStart(data) {
  return request({
    url: "/ptz/start",
    method: "post",
    data,
  });
}

export function ptzStop(data) {
  return request({
    url: "/ptz/stop",
    method: "post",
    data,
  });
}
