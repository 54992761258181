import { createRouter, createWebHistory } from "vue-router";
import { message } from "ant-design-vue";
import { useUserStore } from "@/stores/user";

import Layout from "@/components/layout/BaseLayout";
import SubBaseLayout from "@/components/layout/SubBaseLayout";

export const constantRoutes = [
  {
    path: "/",
    redirect: "/video-surveillance/surveillance-camera/all-device",
  },
  {
    path: "/login",
    name: "login",
    hidden: false,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
];

export const asyncRoutes = [
  {
    id: "1",
    path: "/video-surveillance",
    name: "VideoSurveillance",
    hidden: false,
    meta: { title: "视频监控" },
    component: Layout,
    children: [
      {
        id: "1-1",
        name: "SurveillanceCamera",
        path: "surveillance-camera",
        meta: { title: "监控摄像机管理" },
        component: SubBaseLayout,
        children: [
          {
            id: "1-1-1",
            name: "AllDevice",
            path: "all-device",
            meta: { title: "所有设备" },
            component: () =>
              import(
                /* webpackChunkName: "SurveillanceCamera" */ "../views/surveillance-camera-yingshi/SurveillanceCamera.vue"
              ),
          },
          {
            id: "1-1-2",
            name: "DeviceTags",
            path: "device-tags",
            meta: { title: "设备标签" },
            component: () =>
              import(
                /* webpackChunkName: "DeviceTags" */ "../views/surveillance-camera/DeviceTags.vue"
              ),
          },
        ],
      },
      {
        id: "1-2",
        name: "VideoAlarm",
        path: "video-alarm/list",
        meta: { title: "AI视频告警" },
        component: () =>
          import(
            /* webpackChunkName: "ListPage" */ "../views/video-alarm/components/ListPage"
          ),
      },
    ],
  },
  {
    id: "2",
    path: "/system-manage",
    name: "SystemManage",
    hidden: false,
    meta: { title: "系统管理" },
    component: Layout,
    children: [
      {
        id: "2-1",
        name: "Profile",
        path: "/profile",
        meta: { title: "我的" },
        hidden: false,
        children: [
          {
            id: "2-1-1",
            name: "ProfileInfo",
            path: "profile-info",
            component: () =>
              import(
                /* webpackChunkName: "ProfileInfo" */ "../views/profile/ProfileInfo.vue"
              ),
            meta: { title: "个人中心" },
          },
          {
            id: "2-1-2",
            name: "Password",
            path: "password",
            component: () =>
              import(
                /* webpackChunkName: "Password" */ "../views/profile/Password.vue"
              ),
            meta: { title: "修改密码" },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...constantRoutes, ...asyncRoutes],
});

router.beforeEach((to, from, next) => {
  const store = useUserStore();
  if (to.path == "/login") {
    next();
  } else {
    if (store.token) {
      next();
    } else {
      message.error("登录失效，请重新登录");
      next("/login");
    }
  }
});

export default router;
