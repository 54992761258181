<script setup>
import { useRoute } from "vue-router";
import { ref, watch } from "vue";

const route = useRoute();
const path = ref("");
watch(
  () => route.path,
  (val) => {
    path.value = val;
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <router-view :key="path" />
</template>

<style scoped></style>
