<template>
  <div class="y-richtext">
    <Toolbar
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
      style="border-bottom: 1px solid #ccc"
    />
    <Editor
      v-model="valueHtml"
      :defaultConfig="editorConfig"
      :mode="mode"
      :style="`height: ${editorHeight}px; overflow-y: hidden`"
      @onCreated="handleCreated"
      @onChange="handleChange"
    />
  </div>
</template>

<script>
import { onBeforeUnmount, ref, shallowRef, computed } from "vue";
// import { DomEditor } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { useStore } from "vuex";

import "@wangeditor/editor/dist/css/style.css";

export default {
  components: {
    Editor,
    Toolbar,
  },
  props: {
    value: {
      type: String,
    },
    editorHeight: {
      type: Number,
      default: 300,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const editorRef = shallowRef();
    const valueHtml = ref("");
    const token = computed(() => store.state.user.token);
    const headers = ref({
      Authorization: `Bearer ${token.value}`,
    });

    const toolbarConfig = {
      excludeKeys: ["insertImage", "insertVideo"],
    };
    const editorConfig = {
      placeholder: "请输入...",
      MENU_CONF: {},
    };

    editorConfig.MENU_CONF["uploadImage"] = {
      server: process.env.VUE_APP_API_BASE_URL + "/file",

      // form-data fieldName ，默认值 'wangeditor-uploaded-image'
      fieldName: "file",

      // 单个文件的最大体积限制，默认为 2M
      maxFileSize: 500 * 1024 * 1024, // 1M

      // // 最多可上传几个文件，默认为 100
      // maxNumberOfFiles: 10,

      // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
      allowedFileTypes: ["image/*"],

      // // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
      // meta: {
      //   token: "xxx",
      //   otherKey: "yyy",
      // },

      // 将 meta 拼接到 url 参数中，默认 false
      metaWithUrl: false,

      // 自定义增加 http  header
      headers: {
        ...headers.value,
      },

      // // 跨域是否传递 cookie ，默认为 false
      // withCredentials: true,

      // 超时时间，默认为 10 秒
      timeout: 60 * 1000, // 5 秒

      // 单个文件上传失败
      onFailed(file, res) {
        // TS 语法
        // onFailed(file, res) {           // JS 语法
        console.log(`${file.name} 上传失败`, res);
      },

      onError(file, err, res) {
        console.log(err);
        console.log(res);
      },

      customInsert(res, insertFn) {
        try {
          insertFn(res.url);
        } catch (error) {
          console.error(error);
        }
      },
    };

    const handleCreated = (editor) => {
      editorRef.value = editor;

      editor.setHtml(props.value);

      // 查看所有key配置
      // setTimeout(() => {
      //   const toolbar = DomEditor.getToolbar(editor);
      //   const currentToolbarConfig = toolbar.getConfig();

      //   console.log(currentToolbarConfig.toolbarKeys);
      // }, 200);
    };

    const handleChange = (editor) => {
      const html = editor.getHtml();
      emit("update:value", html);
    };

    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });

    return {
      editorRef,
      valueHtml,
      mode: "simple",
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange,
    };
  },
};
</script>

<style lang="less" scoped>
.y-richtext {
  border: 1px solid #ccc;
}
</style>
