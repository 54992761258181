import AREA from "./area";

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

Array.prototype.toTree = function (a = "superior", b = "subordinates") {
  var map = {},
    tree = [];

  for (const item of this) {
    map[item.id] = item;
  }

  for (const node of this) {
    if (node[a] && node[a].id) {
      if (map[node[a].id] !== undefined) {
        if (!map[node[a].id][b]) {
          map[node[a].id][b] = [];
        }

        map[node[a].id][b].push(node);
      }
    } else {
      tree.push(node);
    }
  }

  return tree;
};

/**
 *
 *
 * @export
 * @param {*} code
 * @param {*} reverse 默认 省、市、区，传true后则为 区、市、省
 * @return {*}
 */
export function renderArea(code, reverse = false) {
  if (code) {
    const [provinceCode, cityCode, countyCode] = code.split(",");
    const province = AREA.province_list[provinceCode];
    const city = AREA.city_list[cityCode];
    const county = AREA.county_list[countyCode];
    const list = [province, city, county];
    if (reverse) {
      list.reverse();
    }
    return list.join("，");
  }
  return "/";
}

const treeToList = (tree, childField = "children") => {
  let queen = [];
  const out = [];
  queen = queen.concat(tree);
  while (queen.length) {
    const first = queen.shift();
    if (first[childField]) {
      queen = queen.concat(first[childField]);
      // delete first[childField];
    }
    out.push(first);
  }
  return out;
};

export { treeToList };
