import request from "./index";
import qs from "qs";

export function getList(params) {
  const query = qs.stringify(params, {
    arrayFormat: "indices",
    allowDots: true,
  });
  return request({
    url: `/alarm-records?${query}`,
    method: "get",
  });
}

export function getID(id) {
  return request({
    url: `/alarm-record/${id}`,
    method: "get",
  });
}

export function getDeviceAlarms(params) {
  return request({
    url: "/alarm-record",
    method: "get",
    params,
  });
}

export function updateDeviceAlarm(param) {
  return request({
    url: `/alarm-record/${param.id}`,
    method: "patch",
    data: param,
  });
}
