<template>
  <div class="side-menu">
    <a-menu
      mode="inline"
      v-model:selectedKeys="selectedKeys"
      v-model:openKeys="openKeys"
      @openChange="onOpenChange"
    >
      <!-- :open-keys="openKeys" -->
      <layout-menu
        v-for="route in asyncRoutes"
        :key="route.id"
        :item="route"
      ></layout-menu>
    </a-menu>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import { useRoute } from "vue-router";

import { asyncRoutes } from "@/router/index";
import { treeToList } from "@/utils/utils";

import LayoutMenu from "./components/LayoutMenu.vue";

export default {
  components: {
    LayoutMenu,
  },
  setup() {
    const route = useRoute();

    const flatRoutes = treeToList([...asyncRoutes]);

    const state = reactive({
      rootSubmenuKeys: [],
      openKeys: [],
      selectedKeys: [],
    });

    const onOpenChange = (openKeys) => {
      console.log(openKeys);
      const latestOpenKey = openKeys.find(
        (key) => state.openKeys.indexOf(key) === -1
      );
      if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        state.openKeys = openKeys;
      } else {
        state.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    };

    const getCurrentKey = (routeName) => {
      const route = flatRoutes.find((item) => item.name == routeName);
      state.selectedKeys = [route.id];
    };

    const getParentKeys = (routeName) => {
      const route = flatRoutes.find((item) => item.name == routeName);
      const key = route.id;
      const arr = key.split("-");
      arr.pop();
      if (arr.length == 1) {
        state.openKeys = arr;
      } else {
        const keys = [];
        arr.forEach((k, index) => {
          console.log(arr.slice(0, index + 1));
          keys.push(arr.slice(0, index + 1).join("-"));
        });
        console.log(keys);
        state.openKeys = keys;
      }
    };

    watch(
      () => route.path,
      () => {
        getCurrentKey(route.name);
        getParentKeys(route.name);
      },
      {
        immediate: true,
      }
    );

    return {
      ...toRefs(state),
      asyncRoutes,
      onOpenChange,
    };
  },
};
</script>

<style lang="less" scoped>
.side-menu {
  position: fixed;
}
</style>
