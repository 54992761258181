<template>
  <a-modal
    :visible="visible"
    title="告警详情"
    @cancel="handleClose"
    :footer="null"
    width="1000px"
  >
    <img :src="props.record.alarmPictures" alt="" />
    <div class="flex footer">
      <div class="flex footer-left">
        <div>时间: {{ fooertData.time }}</div>
        <div>
          告警类型: <span>{{ fooertData.type }}</span>
        </div>
        <div>设备: {{ fooertData.device }}</div>
      </div>
      <div class="flex footer-right">
        <a-button type="link" @click="toReplay">查看告警回放</a-button>
        <a-button type="link" @click="toMonitor">查看实时监控</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { defineProps, onMounted, reactive, defineEmits } from "vue";
import { updateDeviceAlarm } from "@/api/videoAlarm";
import dayjs from "dayjs";
import { renderAlarmType } from "@/utils/ys-alarm-types";
import { useRouter } from "vue-router";
const router = useRouter();

// defineEmits
const props = defineProps({
  record: {
    type: Object,
  },
});
const emit = defineEmits(["ok", "cancel", "success"]);
const fooertData = reactive({
  time: dayjs(props.record.alarmTime).format("YYYY-MM-DD HH:mm:ss"),
  type: renderAlarmType(props.record.alarmType),
  device: props.record.camera.name,
});
const handleClose = async () => {
  emit("success");
  emit("update:visible", false);
};
const init = async () => {
  console.log(props.record);
  await updateDeviceAlarm({
    id: props.record.id,
    isRead: true,
  });
};

const toReplay = async () => {
  router.push({
    path: "/video-surveillance/video-alarm/list",
    query: {
      replay: true,
      id: props.record.id,
    },
  });
  emit("success", true);
};
const toMonitor = () => {
  router.push({
    path: "/video-surveillance/video-alarm/list",
    query: {
      monitor: true,
      id: props.record.id,
    },
  });
  emit("success", true);
};

onMounted(() => {
  init();
});
</script>
<style lang="less" scoped>
img {
  width: 952px;
}
.footer {
  display: flex;
  justify-content: space-between;
  .footer-left {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    > div {
      margin-right: 32px;
      > span {
        color: red;
      }
    }
  }
}
::v-deep {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
}
</style>
