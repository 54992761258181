<template>
  <div class="box flex">
    <div class="loadingio-spinner-spinner-dylklxez0ll">
      <div class="ldio-z6bk1hf0fn">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YLoading",
};
</script>

<style scoped>
.box {
  position: absolute;
  z-index: 17;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  /* background-color: rgba(0, 49, 77, 1); */
}

@keyframes ldio-z6bk1hf0fn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-z6bk1hf0fn div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-z6bk1hf0fn linear 1s infinite;
  background: #0b7ade;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}
.ldio-z6bk1hf0fn div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #0b7ade;
}
.ldio-z6bk1hf0fn div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #0b7ade;
}
.loadingio-spinner-spinner-dylklxez0ll {
  width: 84px;
  height: 84px;
  display: inline-block;
  overflow: hidden;
  background: none;
  margin: 0 auto;
}
.ldio-z6bk1hf0fn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.84);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-z6bk1hf0fn div {
  box-sizing: content-box;
}
</style>
