<template>
  <a-config-provider :locale="locale">
    <template #renderEmpty>
      <div style="text-align: center">
        <img src="../src/assets/images/empty-data.png" />
      </div>
    </template>
    <router-view />
  </a-config-provider>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useUserStore } from "@/stores/user";
import { useYingshiStore } from "@/stores/yingshi";

import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

export default {
  setup() {
    console.log("-----------------");

    const store = useStore();

    const loadRoutes = () => {
      store.dispatch("permission/setRoutes");
    };

    // 获取萤石云accessToken
    const getYSToken = () => {
      const userStore = useUserStore();
      const ysStore = useYingshiStore();
      if (userStore.token) {
        ysStore.getToken();
      }
    };

    onMounted(() => {
      getYSToken();
      loadRoutes();
    });

    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="less"></style>
